
import {mapState} from 'vuex';
import latestMagazine from '~/graphql/queries/latestMagazine.gql';

export default {
  apollo: {
    latestMagazine: {
      query: latestMagazine,
    },
  },
  data: () => ({
    open: false,
    latestMagazine: {},
  }),
  computed: {
    ...mapState({
      menuItems: state => state.menuMain,
      settings: state => state.settings,
    }),
    logoText() {
      return this.settings.find(setting => setting.key === 'tekst_onder_logo');
    },
  },
  methods: {
    async logout() {
      await this.$auth.logout();
    },
  },
};
