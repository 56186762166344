export const FieldInput = () => import('../../components/fields/Input.vue' /* webpackChunkName: "components/field-input" */).then(c => wrapFunctional(c.default || c))
export const FieldTextarea = () => import('../../components/fields/Textarea.vue' /* webpackChunkName: "components/field-textarea" */).then(c => wrapFunctional(c.default || c))
export const CardBlog = () => import('../../components/cards/Blog.vue' /* webpackChunkName: "components/card-blog" */).then(c => wrapFunctional(c.default || c))
export const CardChosenRecipe = () => import('../../components/cards/ChosenRecipe.vue' /* webpackChunkName: "components/card-chosen-recipe" */).then(c => wrapFunctional(c.default || c))
export const CardMagazine = () => import('../../components/cards/Magazine.vue' /* webpackChunkName: "components/card-magazine" */).then(c => wrapFunctional(c.default || c))
export const CardRecipe = () => import('../../components/cards/Recipe.vue' /* webpackChunkName: "components/card-recipe" */).then(c => wrapFunctional(c.default || c))
export const CardRecipeCheckbox = () => import('../../components/cards/RecipeCheckbox.vue' /* webpackChunkName: "components/card-recipe-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CardSearchBlog = () => import('../../components/cards/SearchBlog.vue' /* webpackChunkName: "components/card-search-blog" */).then(c => wrapFunctional(c.default || c))
export const CardWeekdayRecipe = () => import('../../components/cards/WeekdayRecipe.vue' /* webpackChunkName: "components/card-weekday-recipe" */).then(c => wrapFunctional(c.default || c))
export const InputCheckbox = () => import('../../components/inputs/Checkbox.vue' /* webpackChunkName: "components/input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const MenukaartAddRecipe = () => import('../../components/menukaart/AddRecipe.vue' /* webpackChunkName: "components/menukaart-add-recipe" */).then(c => wrapFunctional(c.default || c))
export const MenukaartChosenMenu = () => import('../../components/menukaart/ChosenMenu.vue' /* webpackChunkName: "components/menukaart-chosen-menu" */).then(c => wrapFunctional(c.default || c))
export const MenukaartMenuViewer = () => import('../../components/menukaart/MenuViewer.vue' /* webpackChunkName: "components/menukaart-menu-viewer" */).then(c => wrapFunctional(c.default || c))
export const MenukaartSelectDay = () => import('../../components/menukaart/SelectDay.vue' /* webpackChunkName: "components/menukaart-select-day" */).then(c => wrapFunctional(c.default || c))
export const MenukaartSelectWeek = () => import('../../components/menukaart/SelectWeek.vue' /* webpackChunkName: "components/menukaart-select-week" */).then(c => wrapFunctional(c.default || c))
export const MenukaartStep1 = () => import('../../components/menukaart/Step1.vue' /* webpackChunkName: "components/menukaart-step1" */).then(c => wrapFunctional(c.default || c))
export const MenukaartStep2 = () => import('../../components/menukaart/Step2.vue' /* webpackChunkName: "components/menukaart-step2" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BlogInfo = () => import('../../components/BlogInfo.vue' /* webpackChunkName: "components/blog-info" */).then(c => wrapFunctional(c.default || c))
export const Blogs = () => import('../../components/Blogs.vue' /* webpackChunkName: "components/blogs" */).then(c => wrapFunctional(c.default || c))
export const Faq = () => import('../../components/Faq.vue' /* webpackChunkName: "components/faq" */).then(c => wrapFunctional(c.default || c))
export const FilterDialog = () => import('../../components/FilterDialog.vue' /* webpackChunkName: "components/filter-dialog" */).then(c => wrapFunctional(c.default || c))
export const MenuChoice = () => import('../../components/MenuChoice.vue' /* webpackChunkName: "components/menu-choice" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const RecipeInfo = () => import('../../components/RecipeInfo.vue' /* webpackChunkName: "components/recipe-info" */).then(c => wrapFunctional(c.default || c))
export const Recipes = () => import('../../components/Recipes.vue' /* webpackChunkName: "components/recipes" */).then(c => wrapFunctional(c.default || c))
export const Related = () => import('../../components/Related.vue' /* webpackChunkName: "components/related" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchNav = () => import('../../components/SearchNav.vue' /* webpackChunkName: "components/search-nav" */).then(c => wrapFunctional(c.default || c))
export const Share = () => import('../../components/Share.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c))
export const SliderButtons = () => import('../../components/SliderButtons.vue' /* webpackChunkName: "components/slider-buttons" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../components/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const Themes = () => import('../../components/Themes.vue' /* webpackChunkName: "components/themes" */).then(c => wrapFunctional(c.default || c))
export const User = () => import('../../components/User.vue' /* webpackChunkName: "components/user" */).then(c => wrapFunctional(c.default || c))
export const FormDynamicForm = () => import('../../components/form/DynamicForm.vue' /* webpackChunkName: "components/form-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const FormOnlyHtml = () => import('../../components/form/OnlyHtml.vue' /* webpackChunkName: "components/form-only-html" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner = () => import('../../components/home/Banner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
