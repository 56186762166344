import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _80c789c4 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _821ec02c = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _625b6c1f = () => interopDefault(import('../pages/evenementen/index.vue' /* webpackChunkName: "pages/evenementen/index" */))
const _5bf7235e = () => interopDefault(import('../pages/gegevens-aanpassen.vue' /* webpackChunkName: "pages/gegevens-aanpassen" */))
const _172bf217 = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _18f07d8c = () => interopDefault(import('../pages/magazine.vue' /* webpackChunkName: "pages/magazine" */))
const _1466caec = () => interopDefault(import('../pages/magazines/index.vue' /* webpackChunkName: "pages/magazines/index" */))
const _59ce5348 = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _88ee5dbe = () => interopDefault(import('../pages/profiel.vue' /* webpackChunkName: "pages/profiel" */))
const _195a2faa = () => interopDefault(import('../pages/recepten/index.vue' /* webpackChunkName: "pages/recepten/index" */))
const _16e0959c = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _689336f2 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _cdc394da = () => interopDefault(import('../pages/verifieer.vue' /* webpackChunkName: "pages/verifieer" */))
const _1dca9d36 = () => interopDefault(import('../pages/wachtwoord-aanpassen.vue' /* webpackChunkName: "pages/wachtwoord-aanpassen" */))
const _6518482f = () => interopDefault(import('../pages/wachtwoord-vergeten.vue' /* webpackChunkName: "pages/wachtwoord-vergeten" */))
const _016ad057 = () => interopDefault(import('../pages/welbewust-assortiment/index.vue' /* webpackChunkName: "pages/welbewust-assortiment/index" */))
const _00ab39ba = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _e362ac02 = () => interopDefault(import('../pages/menu/samenstellen.vue' /* webpackChunkName: "pages/menu/samenstellen" */))
const _81def0e4 = () => interopDefault(import('../pages/services/aanvragen.vue' /* webpackChunkName: "pages/services/aanvragen" */))
const _5d411535 = () => interopDefault(import('../pages/templates/default.vue' /* webpackChunkName: "pages/templates/default" */))
const _1df0bfc8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8434cc54 = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _60a4cad7 = () => interopDefault(import('../pages/evenementen/_slug.vue' /* webpackChunkName: "pages/evenementen/_slug" */))
const _12b029a4 = () => interopDefault(import('../pages/magazines/_slug.vue' /* webpackChunkName: "pages/magazines/_slug" */))
const _1cc7723a = () => interopDefault(import('../pages/recepten/_slug.vue' /* webpackChunkName: "pages/recepten/_slug" */))
const _6c007982 = () => interopDefault(import('../pages/services/_slug.vue' /* webpackChunkName: "pages/services/_slug" */))
const _7d074558 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _0097a1e2 = () => interopDefault(import('../pages/welbewust-assortiment/_slug.vue' /* webpackChunkName: "pages/welbewust-assortiment/_slug" */))
const _ba509718 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blogs",
    component: _80c789c4,
    name: "blogs"
  }, {
    path: "/contact",
    component: _821ec02c,
    name: "contact"
  }, {
    path: "/evenementen",
    component: _625b6c1f,
    name: "evenementen"
  }, {
    path: "/gegevens-aanpassen",
    component: _5bf7235e,
    name: "gegevens-aanpassen"
  }, {
    path: "/inloggen",
    component: _172bf217,
    name: "inloggen"
  }, {
    path: "/magazine",
    component: _18f07d8c,
    name: "magazine"
  }, {
    path: "/magazines",
    component: _1466caec,
    name: "magazines"
  }, {
    path: "/menu",
    component: _59ce5348,
    name: "menu"
  }, {
    path: "/profiel",
    component: _88ee5dbe,
    name: "profiel"
  }, {
    path: "/recepten",
    component: _195a2faa,
    name: "recepten"
  }, {
    path: "/registreren",
    component: _16e0959c,
    name: "registreren"
  }, {
    path: "/services",
    component: _689336f2,
    name: "services"
  }, {
    path: "/verifieer",
    component: _cdc394da,
    name: "verifieer"
  }, {
    path: "/wachtwoord-aanpassen",
    component: _1dca9d36,
    name: "wachtwoord-aanpassen"
  }, {
    path: "/wachtwoord-vergeten",
    component: _6518482f,
    name: "wachtwoord-vergeten"
  }, {
    path: "/welbewust-assortiment",
    component: _016ad057,
    name: "welbewust-assortiment"
  }, {
    path: "/zoekresultaten",
    component: _00ab39ba,
    name: "zoekresultaten"
  }, {
    path: "/menu/samenstellen",
    component: _e362ac02,
    name: "menu-samenstellen"
  }, {
    path: "/services/aanvragen",
    component: _81def0e4,
    name: "services-aanvragen"
  }, {
    path: "/templates/default",
    component: _5d411535,
    name: "templates-default"
  }, {
    path: "/",
    component: _1df0bfc8,
    name: "index"
  }, {
    path: "/blogs/:slug",
    component: _8434cc54,
    name: "blogs-slug"
  }, {
    path: "/evenementen/:slug",
    component: _60a4cad7,
    name: "evenementen-slug"
  }, {
    path: "/magazines/:slug",
    component: _12b029a4,
    name: "magazines-slug"
  }, {
    path: "/recepten/:slug",
    component: _1cc7723a,
    name: "recepten-slug"
  }, {
    path: "/services/:slug",
    component: _6c007982,
    name: "services-slug"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _7d074558,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/welbewust-assortiment/:slug?",
    component: _0097a1e2,
    name: "welbewust-assortiment-slug"
  }, {
    path: "/*",
    component: _ba509718,
    name: "*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
