
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, Array, String, Number],
      default: '',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  computed: {
    firstError() {
      if (!this.errorMessages.length) {
        return null;
      }

      return this.errorMessages[0];
    },
  },
  watch: {
    inputValue(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.inputValue = val;
    },
  },
  methods: {
    blurField() {
      // this.$store.dispatch('validation/clearErrors');
    },
  },
};
