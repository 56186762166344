
export default {
  data: () => ({
    dropdown: false,
  }),
  watch: {
    $route: {
      deep: true,
      handler() {
        this.dropdown = false;
      },
    },
  },
  methods: {
    async logout() {
      await this.$auth.logout();
      this.dropdown = false;
    },
  },
};
