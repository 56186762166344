import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faArrowRight,
  faSearch,
  faHeart as farHeart,
  faTag,
} from '@fortawesome/pro-regular-svg-icons';

import {faTimes} from '@fortawesome/pro-light-svg-icons';

import {
  faBookOpen,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faHeart,
  faLink,
  faSortDown,
  faStar,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faFacebookF,
  faLinkedinIn,
  faPinterestP,
  faYoutube,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

library.add(
  faArrowRight,
  faBookOpen,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faFacebookF,
  faHeart,
  farHeart,
  faLink,
  faLinkedinIn,
  faPinterestP,
  faSearch,
  faSortDown,
  faStar,
  faTag,
  faTimes,
  faUser,
  faYoutube,
  faInstagram,
  faTwitter,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
