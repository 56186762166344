import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import possibleTypes from './assets/possibleTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: possibleTypes,
});

export default function ({app}) {
  const linkOptions = {
    uri: process.env.API_URL.replace('/api', '') + '/graphql',
    fetch: (uri, options) => {
      options.headers.authorization = app.$cookies.get('auth._token.local');

      return fetch(uri, options);
    },
  };

  return {
    httpLinkOptions: linkOptions,
    batching: {
      options: linkOptions,
    },
    cache: new InMemoryCache({
      fragmentMatcher,
    }),
  };
}
