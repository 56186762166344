
import {mapState} from 'vuex';

export default {
  data: () => ({
    icons: {
      facebook: ['fab', 'facebook-f'],
      twitter: ['fab', 'twitter'],
      instagram: ['fab', 'instagram'],
      linkedin: ['fab', 'linkedin-in'],
      pinterest: ['fab', 'pinterest-p'],
      youtube: ['fab', 'youtube'],
    },
    footerMenu: [
      {
        name: 'Over Served',
        url: '/over-served',
      },
      {
        name: 'Privacy disclaimer',
        url: '/privacy-disclaimer',
      },
      {
        name: 'Contact',
        url: '/contact',
      },
    ],
  }),
  computed: {
    ...mapState({
      settings: state => state.settings,
    }),
    socials() {
      return this.settings.filter((setting) => {
        if (!setting.value) {
          return false;
        }
        return setting.group === 'Social media';
      });
    },
  },
};
