import pageQuery from '~/graphql/queries/page';
import settingsQuery from '~/graphql/queries/settings';
import menuQuery from '~/graphql/queries/menu';

export const state = () => ({
  release: process.env.RELEASE,
  settings: [],
  page: {seo: {}, template: {}},
  menuMain: {},
  menuFooter: {},
  host: null,
});

export const actions = {
  async nuxtServerInit({dispatch, commit}, {req}) {
    commit('SET_HOST', `${req.protocol || 'http'}://${req.headers.host}`);
    await dispatch('storeDispatchFunc');
  },

  async storeDispatchFunc({commit}) {
    const [settings, menuMain] = await Promise.all([
      this.app.apolloProvider.defaultClient.query({
        query: settingsQuery,
      }),
      this.app.apolloProvider.defaultClient.query({
        query: menuQuery,
        variables: {
          name: 'Hoofdmenu',
        },
      }),
    ]);

    commit('SET_SETTINGS', settings.data.settings);
    commit('SET_MENU_MAIN', menuMain.data.menu.items);
  },

  async getPage({commit}, slug = 'home') {
    const {
      data: {page},
    } = await this.app.apolloProvider.defaultClient.query({
      query: pageQuery,
      fetchPolicy: 'no-cache',
      variables: {
        slug: slug === '' ? 'home' : slug,
      },
    });

    if (!page) {
      const e = new Error();
      e.statusCode = 410;
      throw e;
    }

    commit('SET_PAGE', page);
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_MENU_MAIN(state, payload) {
    state.menuMain = payload;
  },
  SET_MENU_FOOTER(state, payload) {
    state.menuFooter = payload;
  },
  SET_HOST(state, payload) {
    state.host = payload;
  },
};
