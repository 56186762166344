import {mapState} from 'vuex';

export default {
  computed: mapState({
    host: state => state.host,
  }),
  methods: {
    getMeta(item) {
      if (item?.title) {
        return {
          title: item.seo?.metaTitle || item.title,
          link: [
            {
              rel: 'canonical',
              href: this.host + this.$route.path,
            },
          ],
          meta: [
            item.seo?.metaDescription
              ? {
                hid: 'description',
                name: 'description',
                content: item.seo.metaDescription,
              }
              : {},
            item.seo?.metaIndex
              ? {
                hid: 'robots',
                name: 'robots',
                content:
                  (item.seo.metaFollow ? 'follow ' : 'nofollow ') +
                  (item.seo.metaIndex ? 'index' : 'noindex'),
              }
              : {},
            {
              hid: 'og:title',
              property: 'og:title',
              content: item.seo?.metaTitle || item.title,
            },
            item.seo?.metaDescription
              ? {
                hid: 'og:description',
                property: 'og:description',
                content: item.seo.metaDescription,
              }
              : {},
            item.image
              ? {
                hid: 'og:image',
                property: 'og:image',
                content: item.image?.url,
              }
              : {},
            {
              hid: 'og:url',
              property: 'og:url',
              content: this.host + this.$route.path,
            },
          ],
        };
      }
    },
  },
};
