export default {
  data: () => ({
    search: null,
  }),
  methods: {
    submit() {
      if (this.search) {
        this.$router.push({name: 'zoekresultaten', query: {q: this.search}});
      }
    },
  },
};
