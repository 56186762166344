
export default {
  props: {
    id: {
      type: Number,
      default: 1,
    },
    html: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      template: {},
      form: {},
      loading: false,
      halfFields: ['Voornaam', 'Achternaam', 'Postcode', 'Woonplaats'],
    };
  },
  async created() {
    const fields = await this.$axios.get('/forms/' + this.id);

    this.template = fields.data.data;

    // Set defaults
    const that = this;
    this.template.fields.forEach((field) => {
      if (field.type === 'checkbox') {
        // Default must be array to work
        that.form[field.name] = [];
      }
    });
  },
  methods: {
    async submit() {
      let showSucces = true;
      this.loading = true;
      const formData = {...this.form};
      try {
        if (this.$listeners.submit) {
          showSucces = await this.$listeners.submit(formData, this.id);
        } else {
          await this.$axios.post('/forms/' + this.id, formData);
        }

        if (showSucces) {
          this.form = {};
          this.$toast.success('Uw bericht is succesvol verzonden.');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    label(field) {
      if (field.required) {
        return `${field.label}*`;
      }

      return field.label;
    },
  },
};
